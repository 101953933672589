var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"bg-scroll",attrs:{"id":"app_layout"}},[_c('section',{staticClass:"grid h-screen overflow-hidden"},[_c('app-sidebar',{ref:"sidebar",on:{"sidebarClose":_vm.onSideBarClose}}),_c('section',{staticClass:"overflow-y-scroll  overflow-x-hidden"},[_vm._t("header",[(_vm.$route.name != 'Map')?_c('header',{staticClass:"grid grid-flow-col items-center grid-cols-12 p-2 h-16 border-b\n            border-bordergray text-textgray display-none"},[_c('div',{staticClass:"grid grid-flow-col items-center col-span-11",class:{
                'justify-end': _vm.$route.name === 'Tasqs',
              }},[(_vm.isSidebarClosed)?_c('span',{staticClass:"material-icons cursor-pointer",class:{
                  'hidden': _vm.$route.name === 'Map'
                },on:{"click":_vm.toggleSidebar}},[_vm._v(" list ")]):_vm._e(),_c('div',{staticClass:"items-center hidden px-2 space-x-2 md:flex-1 md:flex md:mr-auto md:ml-5",class:{
                  'relative': _vm.$route.name === 'Tasqs',
                }})]),(_vm.$route.name !== 'Tasqs')?_c('div',{},[_c('div',[_c('div',{staticClass:"relative"},[_c('span',{ref:"button",staticClass:"material-icons cursor-pointer",staticStyle:{"float":"right","margin-right":"10px"},attrs:{"id":"top-vertical-setting"},on:{"click":function($event){_vm.settingsOpen = !_vm.settingsOpen}}},[_vm._v(" more_vert ")]),(_vm.settingsOpen)?_c('div',{directives:[{name:"closable",rawName:"v-closable",value:({
                        exclude: ['button'],
                        handler: 'closeFilterDialog'
                      }),expression:"{\n                        exclude: ['button'],\n                        handler: 'closeFilterDialog'\n                      }"}],staticClass:"absolute z-50 w-40 mt-3 px-2 space-y-1 transform bg-white rounded-md right-0 translate-x-1\n                      min-w-max bg-charcoal shadow-lg rounded-md",on:{"click":function($event){_vm.settingsOpen = false}}},[_c('ul',{staticClass:"grid my-2"},[_c('li',[_c('a',{staticClass:"px-3 py-2 rounded-md block text-base transition border-l-4\n                            hover:border-cyan hover:bg-gray-500 border-transparent",on:{"click":function($event){return _vm.handleSidebarItemClick(_vm.userSettingsPageLink)}}},[_vm._v(" Settings ")])]),_c('li',[_c('a',{staticClass:"px-3 py-2 rounded-md block text-base transition border-l-4\n                            hover:border-cyan hover:bg-gray-500 border-transparent",on:{"click":_vm.logout}},[_vm._v(" Logout ")])])])]):_vm._e()])])]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mb-0 md:mb-px"},[_vm._t("default")],2)],2),_c('app-footer',{ref:"footer"})],1),_c('t-alert',{staticClass:"md:bottom-3 md:right-3",attrs:{"show":_vm.showAlert},on:{"hidden":function($event){_vm.showAlert = false}}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }