










































































































































import { Component, Vue } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import { clearSession } from '@/lib/auth';
import { getComponent } from '@/utils/helpers';
import { SHOW_ALERT } from '@/lib/constants';

@Component({
  components: {
    AppSidebar: () => getComponent('common/AppSidebar'),
    AppFooter: () => getComponent('common/AppFooter'),
  },
})
export default class AppLayout extends Vue {
  isSidebarClosed = false;

  notificationsOpen = false;

  settingsOpen = false;

  showAlert = false;

  alertText = '';

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  }


  sleep(ms) {
	return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async created() {
    this.$eventBus.$on(SHOW_ALERT, (text) => {
      this.showAlert = true;
      this.alertText = text;
		this.timeAlert(4000)
    });
  }


  closeFilterDialog() {
    this.settingsOpen = false;
  }

  async timeAlert(time) {
	  await this.sleep(time)
	this.showAlert = false;
      this.alertText = "";
  }

    handleSidebarItemClick(link) {
    this.$router.push({
      name: link.to,
    });
  }

  logout() {
    clearSession();
    this.$router.push({ name: 'Login' });
  }

  onSideBarClose() {
    this.isSidebarClosed = true;
    

    
  }

  toggleSidebar() {
    // @ts-ignore
    // eslint-disable-next-line no-unused-expressions
    this.$refs.sidebar.toggleSidebarMenu();
    this.isSidebarClosed = false;
  }
}
